<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit">
      <div slot="content">
        <a-card title="等级规则">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="车辆类别" prop="carCategoryId">-->
<!--                  <j-category-car-select-->
<!--                    props="{value:true}"-->
<!--                    placeholder="请选择分类"-->
<!--                    v-model="form.carCategoryId"-->
<!--                    :disabled="isDetail"-->
<!--                  />-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="对应商品" prop="productId">-->
<!--                  <a-select show-search  v-model="form.productId" optionFilterProp="label" placeholder="请选择商品">-->
<!--                    <a-select-option v-for="item in goodsList" :key="item.productId" :value="item.productId" :label="item.name">-->
<!--                      {{item.name}}-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="等级名称" prop="level">
                  <a-input v-model="form.level" :maxLength="50" :disabled="isDetail" allowClear></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="等级排序（升序）" prop="levelOrder">-->
<!--                  <a-row :gutter="16">-->
<!--                    <a-col :span="8">-->
<!--                      <a-input-number style="width: 100%" size="large" v-model="form.levelOrder" :min="1" :max="9999999999" :disabled="isDetail"></a-input-number>-->
<!--                    </a-col>-->
<!--                  </a-row>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
<!--            <a-row>-->
<!--              <a-col :span="10">-->
<!--                <a-form-model-item label="最小时间" prop="maxTime">-->
<!--                  <a-row :gutter="16">-->
<!--                    <a-col :span="8">-->
<!--                      <a-input-number style="width: 100%" size="large" v-model="form.maxScore" :min="0" step="0.01" :max="9999999999" :disabled="isDetail"></a-input-number>-->
<!--                    </a-col>-->
<!--                    <a-col :span="4">秒</a-col>-->
<!--                  </a-row>-->
<!--                </a-form-model-item>-->
<!--              </a-col>-->
<!--            </a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="最快时间" prop="minTime">
                  <a-row :gutter="16">
                    <a-col :span="8">
                      <a-input-number style="width: 100%" size="large" v-model="form.minScore" :min="1" step="0.01" :max="9999999999" :disabled="isDetail"></a-input-number>
                    </a-col>
                    <a-col :span="4">秒</a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { levelAddOrEdit, levelInfo } from '@/api/level';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';
import JCategoryCarSelect from '@/components/JCategoryCarSelect';
import { goodsAll } from '@/api/goods';

export default {
  name: 'LevelForm',
  mixins: [formMixin],
  components: {
    FormFrame,
    JCategoryCarSelect,
  },
  data() {
    return {
      goodsList: [],
      form: {
        carCategoryId: '0',
        productId: 9,
        level: '',
        levelOrder: 1,
        maxScore: 0,
        minScore: 1,
      },
      rules: {
        // carCategoryId: [
        //   {
        //     required: true,
        //     message: '请选择车辆类型',
        //     trigger: 'blur',
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (this.form.carCategoryId) {
        //         callback();
        //       }
        //       callback(new Error('请选择车辆类型'));
        //     },
        //   }
        // ],
        // productId: [
        //   {
        //     required: true,
        //     message: '请选择对应商品',
        //     trigger: 'blur',
        //   },
        // ],
        level: [
          {
            required: true,
            message: '请输入等级名称',
            trigger: 'blur',
          },
        ],
        // levelOrder: [
        //   {
        //     required: true,
        //     message: '请输入等级序号',
        //     trigger: 'blur',
        //   },
        // ],
        // maxScore: [
        //   {
        //     required: true,
        //     message: '请输入最大时间',
        //     trigger: 'blur',
        //   },
        // ],
        minScore: [
          {
            required: true,
            message: '请输入最快时间',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getAllGoods();
  },
  methods: {
    // 获取所有可用商品
    getAllGoods() {
      goodsAll().then((data) => {
        this.goodsList = data;
        if (this.paramsId) {
          this.getData(this.paramsId);
        }
      });
    },
    // 获取信息
    getData(id) {
      levelInfo(id).then((data) => {
        this.form = data;
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          };
          console.log(params);
          levelAddOrEdit(params).then((res) => {
            if (res) {
              this.$message.success(res.message || '操作成功');
              this.handleBack();
            } else {
              this.$message.error('操作失败');
              return false;
            }
          });
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
